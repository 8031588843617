import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { MEDIA_LIBRARY_CUSTOM_ROUTES } from "features/MediaLibrary/constants/MediaLibrary.urlPaths";
import { GJS_PAGE_BUILDER_CUSTOM_ROUTES } from "features/SalesPages/modules/GJSSalePageBuilder/constants/GJSSalePageBuilder.urlPaths";
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import ExlyLoader from "ui/modules/ExlyLoader";
import { LazyLoadRoutes } from "./lazyLoadRoutes";
import { ONBOARDING_CUSTOM_ROUTES } from "features/Onboarding/constants/Onboarding.urlPaths";

// @dev For lazy loading named exported components, we need to resolve the promise with setting respective component as default
// Example syntax -> const <Component_Name> = React.lazy(() => import(<path>).then(module => { default: module.<Component_Name> }))

const SignUp = React.lazy(() => import("ui/pages/onboarding/SignUp"));

const onboarding = () => <SignUp />;

const BookedRedirect = () => {
  window.location.href = `${window.location.origin}/#/my-bookings`;
  return <></>;
};

const ManageThankYouAndUpsell = React.lazy(() =>
  import("ui/pages/ThankYou/pages/ManageThankYouAndUpsell")
);

const ThankYouUpdate = React.lazy(() =>
  import("ui/pages/ThankYou/pages/Update")
);

const ManageBrandedCommunityHome = React.lazy(() =>
  import(
    "ui/pages/ManageBrandedCommunity/ManageCommunityHome/ManageCommunityHome"
  )
);

const WhatsappBroadcastCustomTemplates = React.lazy(() =>
  import("ui/pages/whatsappBroadcast/create/CustomTemplates")
);

const EmailDripSetup = React.lazy(() =>
  import("ui/pages/EmailDrip/pages/EmailDripSetup")
);

const EmailDripFlow = React.lazy(() =>
  import("ui/pages/EmailDrip/pages/EmailDripFlow")
);

const EmailDripReview = React.lazy(() =>
  import("ui/pages/EmailDrip/pages/EmailDripReview/EmailDripReview")
);

const EditDripEmail = React.lazy(() =>
  import("ui/pages/EmailDrip/pages/EditDripEmail/EditDripEmail")
);
const WebinarListingsAttendance = React.lazy(() =>
  import("ui/pages/ZoomAttendance/WebinarListingsAttendance")
);

const Whatsapp = React.lazy(() =>
  import("ui/pages/marketing/sales/components/guides/Whatsapp")
);

const OrderBump = React.lazy(() =>
  import("ui/pages/schedule/ManageOffering/pages/OrderBump")
);

const OfferingInvoiceManagement = React.lazy(() =>
  import("features/OfferingInvoiceManagement/OfferingInvoiceManagement")
);

const SelectTemplate = React.lazy(() =>
  import("../ui/pages/TemplatePreview/pages").then((module) => ({
    default: module.SelectTemplate,
  }))
);
const SelectTemplateTags = React.lazy(() =>
  import("../ui/pages/TemplatePreview/pages").then((module) => ({
    default: module.SelectTemplateTags,
  }))
);
const InitialPreviewDetail = React.lazy(() =>
  import("../ui/pages/TemplatePreview/pages").then((module) => ({
    default: module.InitialPreviewDetail,
  }))
);

const CreateScheduleType = React.lazy(() =>
  import("ui/pages/schedule/CreateScheduleType")
);
const OnlineCourse = React.lazy(() => import("schedule-v2/RecordedContent"));
const ScheduleList = React.lazy(() => import("ui/pages/schedule/ScheduleList"));
const Listings = React.lazy(() => import("ui/pages/schedule/Listings"));
const CreateLink = React.lazy(() =>
  import("ui/pages/payments/CreateLink/index")
);
const PayoutDetails = React.lazy(() =>
  import("ui/pages/transactions/PayoutDetails")
);
const ComposeEmail = React.lazy(() =>
  import("ui/pages/communications/CustomEmail/ComposeEmail/ComposeEmail")
);
const Dashboard = React.lazy(() => import("ui/pages/Dashboard"));
const GuidesTutorials = React.lazy(() =>
  import("ui/pages/guidesTutorials").then((module) => ({
    default: module.GuidesTutorials,
  }))
);
const Notifications = React.lazy(() =>
  import("ui/pages/notifications").then((module) => ({
    default: module.Notifications,
  }))
);
const Sales = React.lazy(() =>
  import("ui/pages/marketing/sales").then((module) => ({
    default: module.Sales,
  }))
);
const BdLogin = React.lazy(() => import("ui/pages/onboarding/BdLogin"));
const WebsiteShare = React.lazy(() =>
  import("ui/pages/onboarding/WebsiteShare")
);
const CustomerTransactions = React.lazy(() =>
  import("ui/pages/customers/CustomerTransactions")
);
const WhatsNext = React.lazy(() => import("ui/pages/WhatsNext"));
const NewSignUp = React.lazy(() => import("ui/pages/newOnboarding/newSignUp"));
const TemplatePreview = React.lazy(() => import("ui/pages/TemplatePreview"));
const AttendanceTracking = React.lazy(() =>
  import("ui/pages/AttendanceTracking")
);
const OrganisationV2 = React.lazy(() => import("ui/pages/OrganisationV2/"));
const SingleMemberDetails = React.lazy(() =>
  import("ui/pages/OrganisationV2/pages/singleMember")
);
const MyAccount = React.lazy(() => import("features/Account/Account"));
const ImportClients = React.lazy(() =>
  import("ui/pages/customers/ImportClients/ImportClients")
);
const CustomInvoice = React.lazy(() =>
  import("ui/pages/transactions/PayoutDetails/CustomInvoice")
);
const Certificates = React.lazy(() =>
  import("ui/pages/communications/Certificates")
);
const PreviewCertificate = React.lazy(() =>
  import(
    "ui/pages/communications/Certificates/pages/PreviewCertificate/PreviewCertificate"
  )
);
const CreateQuickPaymentPage = React.lazy(() =>
  import("schedule-v2/QuickPaymentPage")
);
const TelegramCommunities = React.lazy(() =>
  import("schedule-v2/TelegramCommunities")
);
const WhatsappCommunities = React.lazy(() =>
  import("schedule-v2/WhatsappCommunities")
);
const WokshopPage = React.lazy(() => import("schedule-v2/WorkshopPages"));
const AppointmentPage = React.lazy(() => import("schedule-v2/Appointments"));
const ClassesPage = React.lazy(() => import("schedule-v2/Classes"));
const Merchandise = React.lazy(() => import("schedule-v2/Merchandise"));
const Webinar = React.lazy(() => import("schedule-v2/Webinar"));
const SalesAndMarketing = React.lazy(() =>
  import("ui/pages/SalesAndMarketing")
);
const MobileHome = React.lazy(() => import("ui/pages/MobileHome"));
const Optout = React.lazy(() =>
  import("ui/pages/transactions/Subscriptions/Optout")
);
const MarketingCreativesHome = React.lazy(() =>
  import("ui/pages/marketing/MarketingCreatives/pages/Home")
);
const MarketingCreativesEdit = React.lazy(() =>
  import("ui/pages/marketing/MarketingCreatives/pages/Edit")
);
const MarketingCreativesDownload = React.lazy(() =>
  import("ui/pages/marketing/MarketingCreatives/pages/Download")
);
const WhatsappBroadcast = React.lazy(() =>
  import("ui/pages/whatsappBroadcast")
);
const Ads = React.lazy(() => import("ui/pages/marketing/Ads"));
const ExlyConnect = React.lazy(() => import("ui/pages/ExlyConnect"));
const UpdateCertificate = React.lazy(() =>
  import(
    "ui/pages/communications/Certificates/pages/UpdateCertificate/UpdateCertificate"
  )
);
const YourScheduleHome = React.lazy(() =>
  import("ui/pages/schedule/YourSchedule/pages/YourScheduleHome")
);
const Reschedule = React.lazy(() =>
  import("ui/pages/schedule/YourSchedule/pages/Reschedule")
);
const BlockedSlots = React.lazy(() =>
  import("ui/pages/schedule/YourSchedule/pages/BlockedSlots")
);
const BlockNewSlots = React.lazy(() =>
  import("ui/pages/schedule/YourSchedule/pages/BlockNewSlots")
);
const AutomatedMessages = React.lazy(() =>
  import("ui/pages/communications/Customer/index")
);
const SeoCategories = React.lazy(() =>
  import("ui/pages/Seo/pages/SeoCategories/SeoCategories")
);
const SearchResultsPage = React.lazy(() =>
  import("ui/pages/Seo/pages/SearchResultsPage/SearchResultsPage")
);
const SeoPerformance = React.lazy(() =>
  import("ui/pages/Seo/pages/SeoPerformance/SeoPerformance")
);
const OrgRoles = React.lazy(() =>
  import("ui/pages/OrganizationV3/pages/OrgRoles/OrgRoles")
);
const MemberProfile = React.lazy(() =>
  import("ui/pages/OrganizationV3/pages/MemberProfile/MemberProfile")
);
const AddEditOrgMember = React.lazy(() =>
  import("ui/pages/OrganizationV3/pages/AddEditOrgMember/AddEditOrgMember")
);
const RolePermissionEdit = React.lazy(() =>
  import("ui/pages/OrganizationV3/pages/RolePermissionEdit/RolePermissionEdit")
);
const ManageOffering = React.lazy(() =>
  import("ui/pages/schedule/ManageOffering")
);
const PreviewBlog = React.lazy(() =>
  import("ui/pages/communications/Blogs/PreviewBlog/PreviewBlog")
);
const BrandedCommunity = React.lazy(() =>
  import("schedule-v2/BrandedCommunity")
);
const Plans = React.lazy(() => import("schedule-v2/Plans"));
const ViewSegment = React.lazy(() =>
  import("ui/pages/customers/Segments/SegmentView/ViewSegment")
);
const CommunicationsSent = React.lazy(() =>
  import("ui/pages/customers/Segments/CommSent")
);
const GoogleCalendar = React.lazy(() =>
  import("ui/pages/marketing/sales/components/guides/GoogleCalendar")
);
const FaceBookPixel = React.lazy(() =>
  import("ui/pages/marketing/sales/components/guides/FaceBookPixel")
);
const GoogleAnalytics = React.lazy(() =>
  import("ui/pages/marketing/sales/components/guides/GoogleAnalytics")
);
const DomainLinking = React.lazy(() =>
  import("ui/pages/marketing/sales/components/guides/DomainLinking")
);
const WordPress = React.lazy(() =>
  import("ui/pages/marketing/sales/components/guides/WordPress")
);
const Pabbly = React.lazy(() =>
  import("ui/pages/marketing/sales/components/guides/Pabbly")
);
const Zoom = React.lazy(() =>
  import("ui/pages/marketing/sales/components/guides/Zoom")
);
const Zapier = React.lazy(() =>
  import("ui/pages/marketing/sales/components/guides/Zapier")
);
const ShipRocket = React.lazy(() =>
  import("ui/pages/marketing/sales/components/guides/ShipRocket")
);
const AddSchedule = React.lazy(() =>
  import("ui/pages/schedule/YourSchedule/pages/AddSchedule")
);
const CreateNewSegment = React.lazy(() =>
  import("ui/pages/customers/Segments/NewSegment/CreateNewSegment")
);
const SeoPerformanceAndAnalytics = React.lazy(() =>
  import(
    "ui/pages/Seo/pages/SeoPerformanceAndAnalytics/SeoPerformanceAndAnalytics"
  )
);
const ManageCommunication = React.lazy(() =>
  import("ui/pages/schedule/MangageCommunication")
);
const EmailCommunication = React.lazy(() =>
  import("ui/pages/schedule/MangageCommunication/components/EmailCommunication")
);
const LeadsCaptured = React.lazy(() =>
  import("ui/pages/Analytics/LeadsCaptured")
);
const TrafficSources = React.lazy(() =>
  import("ui/pages/Analytics/TrafficSources")
);
const SalesRevenue = React.lazy(() =>
  import("ui/pages/Analytics/SalesRevenue")
);
const PageVisitors = React.lazy(() =>
  import("ui/pages/Analytics/PageVisitors")
);
const GoogleSearchReport = React.lazy(() =>
  import("ui/pages/Seo/pages/GoogleSearchReport")
);
const SalesPagesSelectTemplate = React.lazy(() =>
  import(
    "ui/pages/SalesPages/pages/SalesPagesSelectTemplate/SalesPagesSelectTemplate"
  )
);
const SalesPageUpdate = React.lazy(() =>
  import("ui/pages/SalesPages/pages/Update")
);
const SalesPagePreviewPage = React.lazy(() =>
  import("ui/pages/SalesPages/pages/SalesPagePreviewPage/SalesPagePreviewPage")
);
const Cart = React.lazy(() =>
  import("ui/pages/Account/pages/Cart").then((module) => ({
    default: module.Cart,
  }))
);
const CrmDetails = React.lazy(() => import("ui/pages/customers/CrmDetails"));

const UpdateCheckoutPageDetails = React.lazy(() =>
  import(
    "features/CheckoutPages/modules/UpdateCheckoutPageDetails/UpdateCheckoutPageDetails"
  )
);

export default [
  ...ONBOARDING_CUSTOM_ROUTES,
  <Route
    exact
    key="/CreateScheduleType"
    path={`/CreateScheduleType`}
    render={(props) => (
      <LazyLoadRoutes LazyElement={CreateScheduleType} props={props} />
    )}
  />,
  <Route
    exact
    key="/CreateFirstOffering"
    path={`/CreateFirstOffering`}
    render={(props) => (
      <LazyLoadRoutes LazyElement={CreateScheduleType} props={props} />
    )}
    noLayout
  />,
  <Route
    exact
    key="/UpdateSchedule/:listingId"
    path={`/UpdateSchedule/:listingId`}
    render={(props) => <LazyLoadRoutes LazyElement={Listings} props={props} />}
  />,
  <Route
    exact
    key={`/${app_route_ids[app_route_keys.manage_offering]}/:listing_uuid`}
    path={`/${app_route_ids[app_route_keys.manage_offering]}/:listing_uuid`}
    render={(props) => (
      <LazyLoadRoutes LazyElement={ManageOffering} props={props} />
    )}
  />,
  <Route
    exact
    key={`/${app_route_ids[app_route_keys.order_bump]}/:offeringUuid`}
    path={`/${app_route_ids[app_route_keys.order_bump]}/:offeringUuid`}
    render={(props) => <LazyLoadRoutes LazyElement={OrderBump} props={props} />}
  />,
  <Route
    exact
    key="/ScheduleList"
    path={`/ScheduleList`}
    render={(props) => (
      <LazyLoadRoutes LazyElement={ScheduleList} props={props} />
    )}
  />,
  <Route
    exact
    key="/CreateLink"
    path={`/CreateLink`}
    render={(props) => (
      <LazyLoadRoutes LazyElement={CreateLink} props={props} />
    )}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.dashboard]}
    path={app_route_ids[app_route_keys.dashboard]}
    render={(props) => <LazyLoadRoutes LazyElement={Dashboard} props={props} />}
  />,
  <Route
    exact
    key="/"
    path="/"
    render={(props) => (
      <LazyLoadRoutes LazyElement={onboarding} props={props} />
    )}
    noLayout
  />,
  <Route
    exact
    key="/signup"
    path="/signup"
    render={(props) => <LazyLoadRoutes LazyElement={NewSignUp} props={props} />}
    noLayout
  />,
  <Route
    exact
    key="/optout/:id"
    path="/optout/:id"
    render={(props) => <LazyLoadRoutes LazyElement={Optout} props={props} />}
    noLayout
  />,
  <Route
    exact
    key="/PayoutDetails"
    path={`/PayoutDetails`}
    render={(props) => (
      <LazyLoadRoutes LazyElement={PayoutDetails} props={props} />
    )}
  />,
  <Route
    exact
    key="/Templates"
    path={`/Templates`}
    render={(props) => (
      <LazyLoadRoutes LazyElement={SelectTemplate} props={props} />
    )}
    noLayout
  />,
  <Route
    exact
    key="/Templates/selectTag"
    path={`/Templates/selectTag`}
    render={(props) => (
      <LazyLoadRoutes LazyElement={SelectTemplateTags} props={props} />
    )}
    noLayout
  />,
  <Route
    exact
    key="/Templates/:templateId"
    path={`/Templates/:templateId`}
    render={(props) => (
      <LazyLoadRoutes LazyElement={InitialPreviewDetail} props={props} />
    )}
    noLayout
  />,
  <Route
    exact
    key="/host/schedules/booked"
    path={`/host/schedules/booked`}
    component={BookedRedirect}
  />,
  <Route
    exact
    key="/ComposeEmail"
    path={`/ComposeEmail`}
    render={(props) => (
      <LazyLoadRoutes LazyElement={ComposeEmail} props={props} />
    )}
  />,
  <Route
    exact
    key="/GuidesTutorials"
    path={"/GuidesTutorials"}
    render={(props) => (
      <LazyLoadRoutes LazyElement={GuidesTutorials} props={props} />
    )}
  />,
  <Route
    exact
    key="/Notifications"
    path={"/Notifications"}
    render={(props) => (
      <LazyLoadRoutes LazyElement={Notifications} props={props} />
    )}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.integrations]}
    path={app_route_ids[app_route_keys.integrations]}
    render={(props) => <LazyLoadRoutes LazyElement={Sales} props={props} />}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.integrations_google_calendar]}
    path={app_route_ids[app_route_keys.integrations_google_calendar]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={GoogleCalendar} props={props} />
    )}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.integrations_facebook_pixel]}
    path={app_route_ids[app_route_keys.integrations_facebook_pixel]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={FaceBookPixel} props={props} />
    )}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.integrations_google_analytics]}
    path={app_route_ids[app_route_keys.integrations_google_analytics]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={GoogleAnalytics} props={props} />
    )}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.integrations_domain_linking]}
    path={app_route_ids[app_route_keys.integrations_domain_linking]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={DomainLinking} props={props} />
    )}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.integrations_wordpress]}
    path={app_route_ids[app_route_keys.integrations_wordpress]}
    render={(props) => <LazyLoadRoutes LazyElement={WordPress} props={props} />}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.integrations_pabbly]}
    path={app_route_ids[app_route_keys.integrations_pabbly]}
    render={(props) => <LazyLoadRoutes LazyElement={Pabbly} props={props} />}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.integrations_zoom]}
    path={app_route_ids[app_route_keys.integrations_zoom]}
    render={(props) => <LazyLoadRoutes LazyElement={Zoom} props={props} />}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.integrations_zapier]}
    path={app_route_ids[app_route_keys.integrations_zapier]}
    render={(props) => <LazyLoadRoutes LazyElement={Zapier} props={props} />}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.shiprocket]}
    path={app_route_ids[app_route_keys.shiprocket]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={ShipRocket} props={props} />
    )}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.integrations_whatsapp]}
    path={app_route_ids[app_route_keys.integrations_whatsapp]}
    render={(props) => <LazyLoadRoutes LazyElement={Whatsapp} props={props} />}
  />,
  <Route
    exact
    key="/BdLogin/:user_id"
    path={"/BdLogin/:user_id"}
    render={(props) => <LazyLoadRoutes LazyElement={BdLogin} props={props} />}
  />,
  <Route
    exact
    key="/myAccount"
    path={"/myAccount"}
    render={(props) => <LazyLoadRoutes LazyElement={MyAccount} props={props} />}
  />,
  <Route
    exact
    key="/CustomerTransactions"
    path="/CustomerTransactions"
    render={(props) => (
      <LazyLoadRoutes LazyElement={CustomerTransactions} props={props} />
    )}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.websiteshare]}
    path={app_route_ids[app_route_keys.websiteshare]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={WebsiteShare} props={props} />
    )}
    noLayout
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.whats_next]}
    path={app_route_ids[app_route_keys.whats_next]}
    render={(props) => <LazyLoadRoutes LazyElement={WhatsNext} props={props} />}
  />,
  // <Route exact keh='/online-course' component={OnlineCourse} />,'y=' path='/online-course' component={OnlineCourse} />,
  <Route
    exact
    key="/PreviewBlog/:blog_uuid"
    path="/PreviewBlog/:blog_uuid"
    render={(props) => (
      <LazyLoadRoutes LazyElement={PreviewBlog} props={props} />
    )}
    noLayout
  />,
  <Route
    exact
    key="/online-course/:id"
    path="/online-course/:id"
    render={(props) => (
      <LazyLoadRoutes LazyElement={OnlineCourse} props={props} />
    )}
  />,
  <Route
    exact
    key="/onboarding/online-course/:id"
    path="/onboarding/online-course/:id"
    render={(props) => (
      <LazyLoadRoutes
        LazyElement={OnlineCourse}
        props={{ ...props, isOnboarding: true }}
      />
    )}
    noLayout
  />,
  <Route
    key={app_route_ids[app_route_keys.ads_online_course]}
    path={app_route_ids[app_route_keys.ads_online_course]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={OnlineCourse} props={props} />
    )}
    noLayout
  />,
  <Route
    exact
    key="/attendance-tracking"
    path="/attendance-tracking"
    render={(props) => (
      <LazyLoadRoutes LazyElement={AttendanceTracking} props={props} />
    )}
  />,
  <Route
    exact
    key="/myschedule/schedule"
    path="/myschedule/schedule"
    render={(props) => (
      <LazyLoadRoutes LazyElement={AddSchedule} props={props} />
    )}
  />,
  <Route
    exact
    key="/TemplatePreview/"
    path="/TemplatePreview/"
    render={(props) => (
      <LazyLoadRoutes LazyElement={TemplatePreview} props={props} />
    )}
    noLayout
  />,
  <Route
    exact
    key="/organisation"
    path="/organisation"
    render={(props) => (
      <LazyLoadRoutes LazyElement={OrganisationV2} props={props} />
    )}
  />,
  <Route
    exact
    key={`/${app_route_ids[app_route_keys.import_clients]}`}
    path={`/${app_route_ids[app_route_keys.import_clients]}`}
    render={(props) => (
      <LazyLoadRoutes LazyElement={ImportClients} props={props} />
    )}
  />,
  <Route
    exact
    key="/organisation/member/:id"
    path="/organisation/member/:id"
    render={(props) => (
      <LazyLoadRoutes LazyElement={SingleMemberDetails} props={props} />
    )}
  />,
  <Route
    exact
    key="/custom-invoice"
    path="/custom-invoice"
    render={(props) => (
      <LazyLoadRoutes LazyElement={CustomInvoice} props={props} />
    )}
  />,
  <Route
    exact
    key="/create-certificate"
    path="/create-certificate"
    render={(props) => (
      <LazyLoadRoutes LazyElement={Certificates} props={props} />
    )}
  />,
  <Route
    exact
    key="/preview-certificate"
    path="/preview-certificate"
    render={(props) => (
      <LazyLoadRoutes LazyElement={PreviewCertificate} props={props} />
    )}
    noLayout
  />,
  <Route
    exact
    key="/update-certificate/:id"
    path="/update-certificate/:id"
    render={(props) => (
      <LazyLoadRoutes LazyElement={UpdateCertificate} props={props} />
    )}
    noLayout
  />,
  <Route
    exact
    key="/onboarding/quick-payment-page/:id"
    path="/onboarding/quick-payment-page/:id"
    render={(props) => (
      <LazyLoadRoutes
        LazyElement={CreateQuickPaymentPage}
        props={{ ...props, isOnboarding: true }}
      />
    )}
    noLayout
  />,
  <Route
    exact
    key="/quick-payment-page/:id"
    path="/quick-payment-page/:id"
    render={(props) => (
      <LazyLoadRoutes LazyElement={CreateQuickPaymentPage} props={props} />
    )}
  />,
  <Route
    exact
    key="/onboarding/telegram-communities/:id"
    path="/onboarding/telegram-communities/:id"
    render={(props) => (
      <LazyLoadRoutes
        LazyElement={TelegramCommunities}
        props={{ ...props, isOnboarding: true }}
      />
    )}
    noLayout
  />,
  <Route
    exact
    key="/telegram-communities/:id"
    path="/telegram-communities/:id"
    render={(props) => (
      <LazyLoadRoutes LazyElement={TelegramCommunities} props={props} />
    )}
  />,
  <Route
    exact
    key="/onboarding/branded-community/:id"
    path="/onboarding/branded-community/:id"
    render={(props) => (
      <LazyLoadRoutes
        LazyElement={BrandedCommunity}
        props={{ ...props, isOnboarding: true }}
      />
    )}
    noLayout
  />,
  <Route
    exact
    key="/branded-community/:id"
    path="/branded-community/:id"
    render={(props) => (
      <LazyLoadRoutes LazyElement={BrandedCommunity} props={props} />
    )}
  />,
  <Route
    exact
    key="/onboarding/whatsapp-communities/:id"
    path="/onboarding/whatsapp-communities/:id"
    render={(props) => (
      <LazyLoadRoutes
        LazyElement={WhatsappCommunities}
        props={{ ...props, isOnboarding: true }}
      />
    )}
    noLayout
  />,
  <Route
    exact
    key="/whatsapp-communities/:id"
    path="/whatsapp-communities/:id"
    render={(props) => (
      <LazyLoadRoutes LazyElement={WhatsappCommunities} props={props} />
    )}
  />,
  <Route
    exact
    key={`/onboarding${app_route_ids[app_route_keys.webinar]}`}
    path={`/onboarding${app_route_ids[app_route_keys.webinar]}`}
    render={(props) => (
      <LazyLoadRoutes
        LazyElement={Webinar}
        props={{ ...props, isOnboarding: true }}
      />
    )}
    noLayout
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.webinar]}
    path={app_route_ids[app_route_keys.webinar]}
    render={(props) => <LazyLoadRoutes LazyElement={Webinar} props={props} />}
  />,
  <Route
    exact
    key="/sales-and-marketing"
    path="/sales-and-marketing"
    render={(props) => (
      <LazyLoadRoutes LazyElement={SalesAndMarketing} props={props} />
    )}
  />,
  <Route
    exact
    key="/mobile-home"
    path="/mobile-home"
    render={(props) => (
      <LazyLoadRoutes LazyElement={MobileHome} props={props} />
    )}
  />,
  <Route
    exact
    key="/onboarding/workshop/:id"
    path="/onboarding/workshop/:id"
    render={(props) => (
      <LazyLoadRoutes
        LazyElement={WokshopPage}
        props={{ ...props, isOnboarding: true }}
      />
    )}
    noLayout
  />,
  <Route
    exact
    key="/workshop/:id"
    path="/workshop/:id"
    render={(props) => (
      <LazyLoadRoutes LazyElement={WokshopPage} props={props} />
    )}
  />,
  <Route
    exact
    key="/onboarding/appointment/:id"
    path="/onboarding/appointment/:id"
    render={(props) => (
      <LazyLoadRoutes
        LazyElement={AppointmentPage}
        props={{ ...props, isOnboarding: true }}
      />
    )}
    noLayout
  />,
  <Route
    exact
    key="/appointment/:id"
    path="/appointment/:id"
    render={(props) => (
      <LazyLoadRoutes LazyElement={AppointmentPage} props={props} />
    )}
  />,
  <Route
    exact
    key="/onboarding/classes/:id"
    path="/onboarding/classes/:id"
    render={(props) => (
      <LazyLoadRoutes
        LazyElement={ClassesPage}
        props={{ ...props, isOnboarding: true }}
      />
    )}
    noLayout
  />,
  <Route
    exact
    key="/classes/:id"
    path="/classes/:id"
    render={(props) => (
      <LazyLoadRoutes LazyElement={ClassesPage} props={props} />
    )}
  />,
  <Route
    exact
    key="/onboarding/merchandise/:id"
    path="/onboarding/merchandise/:id"
    render={(props) => (
      <LazyLoadRoutes
        LazyElement={Merchandise}
        props={{ ...props, isOnboarding: true }}
      />
    )}
    noLayout
  />,
  <Route
    exact
    key="/merchandise/:id"
    path="/merchandise/:id"
    render={(props) => (
      <LazyLoadRoutes LazyElement={Merchandise} props={props} />
    )}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.create_plan]}
    path={app_route_ids[app_route_keys.create_plan]}
    render={(props) => <LazyLoadRoutes LazyElement={Plans} props={props} />}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.marketing_creatives]}
    path={app_route_ids[app_route_keys.marketing_creatives]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={MarketingCreativesHome} props={props} />
    )}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.marketing_creatives_edit]}
    path={app_route_ids[app_route_keys.marketing_creatives_edit]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={MarketingCreativesEdit} props={props} />
    )}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.marketing_creatives_download]}
    path={app_route_ids[app_route_keys.marketing_creatives_download]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={MarketingCreativesDownload} props={props} />
    )}
  />,
  <Route
    exact
    key="/whatsapp-broadcasts/:action?"
    path="/whatsapp-broadcasts/:action?"
    render={(props) => (
      <LazyLoadRoutes LazyElement={WhatsappBroadcast} props={props} />
    )}
  />,
  <Route
    exact
    key="/ads"
    path={`/ads`}
    render={(props) => <LazyLoadRoutes LazyElement={Ads} props={props} />}
    noLayout
  />,
  <Route
    exact
    key="/exly-connect"
    path="/exly-connect"
    render={(props) => (
      <LazyLoadRoutes LazyElement={ExlyConnect} props={props} />
    )}
  />,
  <Route
    exact
    key="/myschedule"
    path="/myschedule"
    render={(props) => (
      <LazyLoadRoutes LazyElement={YourScheduleHome} props={props} />
    )}
  />,
  <Route
    exact
    key="/myschedule/reschedule"
    path="/myschedule/reschedule"
    render={(props) => (
      <LazyLoadRoutes LazyElement={Reschedule} props={props} />
    )}
  />,
  <Route
    exact
    key="/myschedule/blocked-slots"
    path="/myschedule/blocked-slots"
    render={(props) => (
      <LazyLoadRoutes LazyElement={BlockedSlots} props={props} />
    )}
  />,
  <Route
    exact
    key="/myschedule/blocked-slots/new"
    path="/myschedule/blocked-slots/new"
    render={(props) => (
      <LazyLoadRoutes LazyElement={BlockNewSlots} props={props} />
    )}
  />,
  <Route
    exact
    key="/notifications/automated-messages"
    path="/notifications/automated-messages"
    render={(props) => (
      <LazyLoadRoutes LazyElement={AutomatedMessages} props={props} />
    )}
  />,
  <Route
    exact
    key="/segments/comm-sent/:segment_id"
    path="/segments/comm-sent/:segment_id"
    render={(props) => (
      <LazyLoadRoutes LazyElement={CommunicationsSent} props={props} />
    )}
  />,
  <Route
    exact
    key="/segments/conditions/:segment_id"
    path="/segments/conditions/:segment_id"
    render={(props) => (
      <LazyLoadRoutes LazyElement={ViewSegment} props={props} />
    )}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.create_segment_conditions]}
    path={app_route_ids[app_route_keys.create_segment_conditions]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={CreateNewSegment} props={props} />
    )}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.edit_segment_condition]}
    path={app_route_ids[app_route_keys.edit_segment_condition]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={CreateNewSegment} props={props} />
    )}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.seo_categories]}
    path={app_route_ids[app_route_keys.seo_categories]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={SeoCategories} props={props} />
    )}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.search_results_page]}
    path={app_route_ids[app_route_keys.search_results_page]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={SearchResultsPage} props={props} />
    )}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.seo_performance_and_analytics]}
    path={app_route_ids[app_route_keys.seo_performance_and_analytics]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={SeoPerformanceAndAnalytics} props={props} />
    )}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.seo_performance]}
    path={app_route_ids[app_route_keys.seo_performance]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={SeoPerformance} props={props} />
    )}
  />,
  <Route
    key={app_route_ids[app_route_keys.organization_roles]}
    exact
    path={app_route_ids[app_route_keys.organization_roles]}
    render={(props) => <LazyLoadRoutes LazyElement={OrgRoles} props={props} />}
  />,
  <Route
    key={app_route_ids[app_route_keys.organization_member_profile]}
    path={app_route_ids[app_route_keys.organization_member_profile]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={MemberProfile} props={props} />
    )}
    noLayout
  />,
  <Route
    key={app_route_ids[app_route_keys.organization_add_member]}
    path={app_route_ids[app_route_keys.organization_add_member]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={AddEditOrgMember} props={props} />
    )}
    noLayout
  />,
  <Route
    key={app_route_ids[app_route_keys.organization_edit_member]}
    path={app_route_ids[app_route_keys.organization_edit_member]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={AddEditOrgMember} props={props} />
    )}
    noLayout
  />,
  <Route
    key={app_route_ids[app_route_keys.organization_edit_role]}
    path={app_route_ids[app_route_keys.organization_edit_role]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={RolePermissionEdit} props={props} />
    )}
    noLayout
  />,
  <Route
    key={app_route_ids[app_route_keys.manage_communications_of_listing]}
    path={app_route_ids[app_route_keys.manage_communications_of_listing]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={ManageCommunication} props={props} />
    )}
    exact
  />,
  <Route
    key={app_route_ids[app_route_keys.manage_communications_email]}
    path={app_route_ids[app_route_keys.manage_communications_email]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={EmailCommunication} props={props} />
    )}
    exact
    noLayout
  />,
  <Route
    key={app_route_ids[app_route_keys.leads_captured]}
    path={app_route_ids[app_route_keys.leads_captured]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={LeadsCaptured} props={props} />
    )}
  />,
  <Route
    key={app_route_ids[app_route_keys.traffic_sources]}
    path={app_route_ids[app_route_keys.traffic_sources]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={TrafficSources} props={props} />
    )}
  />,
  <Route
    key={app_route_ids[app_route_keys.sales_revenue]}
    path={app_route_ids[app_route_keys.sales_revenue]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={SalesRevenue} props={props} />
    )}
  />,
  <Route
    key={app_route_ids[app_route_keys.page_visitors]}
    path={app_route_ids[app_route_keys.page_visitors]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={PageVisitors} props={props} />
    )}
  />,
  <Route
    key={app_route_ids[app_route_keys.google_search_reports]}
    path={app_route_ids[app_route_keys.google_search_reports]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={GoogleSearchReport} props={props} />
    )}
  />,
  <Route
    key={app_route_ids[app_route_keys.sales_pages_update_details]}
    path={app_route_ids[app_route_keys.sales_pages_update_details]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={SalesPageUpdate} props={props} />
    )}
    noLayout
  />,
  <Route
    key={app_route_ids[app_route_keys.checkout_page_update_details]}
    path={app_route_ids[app_route_keys.checkout_page_update_details]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={UpdateCheckoutPageDetails} props={props} />
    )}
    noLayout
  />,
  <Route
    key={`/${app_route_ids[app_route_keys.cart]}`}
    path={`/${app_route_ids[app_route_keys.cart]}`}
    render={(props) => <LazyLoadRoutes LazyElement={Cart} props={props} />}
  />,
  <Route
    key={"/" + app_route_ids[app_route_keys.sales_pages_update_template]}
    path={"/" + app_route_ids[app_route_keys.sales_pages_update_template]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={SalesPagesSelectTemplate} props={props} />
    )}
    noLayout
  />,
  <Route
    key={"/" + app_route_ids[app_route_keys.sales_pages_preview]}
    path={"/" + app_route_ids[app_route_keys.sales_pages_preview]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={SalesPagePreviewPage} props={props} />
    )}
    noLayout
  />,
  <Route
    key={app_route_ids[app_route_keys.customer_details]}
    path={app_route_ids[app_route_keys.customer_details]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={CrmDetails} props={props} />
    )}
  />,
  <Route
    key={app_route_ids[app_route_keys.whatsapp_template]}
    path={app_route_ids[app_route_keys.whatsapp_template]}
    render={(props) => (
      <LazyLoadRoutes
        LazyElement={WhatsappBroadcastCustomTemplates}
        props={props}
      />
    )}
  />,
  <Route
    key={app_route_ids[app_route_keys.email_drip_setup]}
    path={app_route_ids[app_route_keys.email_drip_setup]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={EmailDripSetup} props={props} />
    )}
  />,
  <Route
    key={app_route_ids[app_route_keys.email_drip_flow]}
    path={app_route_ids[app_route_keys.email_drip_flow]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={EmailDripFlow} props={props} />
    )}
  />,
  <Route
    key={app_route_ids[app_route_keys.email_drip_review]}
    path={app_route_ids[app_route_keys.email_drip_review]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={EmailDripReview} props={props} />
    )}
  />,
  <Route
    key={app_route_ids[app_route_keys.email_drip_edit_email]}
    path={app_route_ids[app_route_keys.email_drip_edit_email]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={EditDripEmail} props={props} />
    )}
  />,
  <Route
    key={app_route_ids[app_route_keys.live_webinar_attendance]}
    path={app_route_ids[app_route_keys.live_webinar_attendance]}
    render={(props) => (
      <Suspense fallback={<ExlyLoader />}>
        <WebinarListingsAttendance {...props} />
      </Suspense>
    )}
  />,
  <Route
    key={"/" + app_route_ids[app_route_keys.manage_branded_community_home]}
    path={"/" + app_route_ids[app_route_keys.manage_branded_community_home]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={ManageBrandedCommunityHome} props={props} />
    )}
    noLayout
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.manage_thankyou_upsell]}
    path={app_route_ids[app_route_keys.manage_thankyou_upsell]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={ManageThankYouAndUpsell} props={props} />
    )}
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.update_thankyou]}
    path={app_route_ids[app_route_keys.update_thankyou]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={ThankYouUpdate} props={props} />
    )}
    noLayout
  />,
  <Route
    exact
    key={app_route_ids[app_route_keys.offering_invoice_management]}
    path={app_route_ids[app_route_keys.offering_invoice_management]}
    render={(props) => (
      <LazyLoadRoutes LazyElement={OfferingInvoiceManagement} props={props} />
    )}
  />,
  ...MEDIA_LIBRARY_CUSTOM_ROUTES,
  ...GJS_PAGE_BUILDER_CUSTOM_ROUTES,
];
